<template>
  <div>
    <b-card title="View Profile">
      <b-row >
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Name</label>
            <p class="font-weight-bold">{{ user.name }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Role</label>
            <p class="font-weight-bold">{{ user.role }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Email</label>
            <p class="font-weight-bold">{{ user.email }}</p>
          </div>
        </b-col>
      </b-row>

    </b-card>
    <b-card title="Dealer Information" v-if="dealer!==undefined && dealer!==''">
      <!--TEMPORARY FOR DEALER INFO PROFILE-->
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Dealer Code</label>
            <p class="font-weight-bold">{{ dealer.dealerCode }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Dealer Name</label>
            <p class="font-weight-bold">{{ dealer.dealerName }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Email</label>
            <p class="font-weight-bold">{{ dealer.dealerEmailAddress }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Address</label>
            <p class="font-weight-bold">{{ dealer.billingAddress1 }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Postal Code</label>
            <p class="font-weight-bold">{{ dealer.postalCode }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Industry Type</label>
            <p class="font-weight-bold">{{ dealer.industryType }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Status</label>
            <p class="font-weight-bold">{{ dealer.status==='' ? 'Active' : 'Inactive' }}</p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import "vue-select/src/scss/vue-select.scss";
import { viewAccess } from '@/utils/utils.js';
import { mapState, mapActions } from 'vuex';
import axios from '@/axios';

export default {
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    user() {
      return this.$store.getters['auth/getActiveUser'];
    },
    dealer(){
      let tempAllDealer = this.$store.getters['dealer/getDealers'].find(e=>{return e._id===this.user.dealer});
            
      return tempAllDealer
    },
    home() {
      return viewAccess()[0]
    },
  },
  created() {
    document.title = 'View Profile | RSP'
  },
  mounted() {
    this.$store
      .dispatch('auth/fetchUser')
      .catch(err => console.log(err))
    this.$store
      .dispatch('dealer/getAllDealers')
      .catch(err => console.log(err))
  },
}
</script>
